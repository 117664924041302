

// Import polyfills:
require('./polyfills.js');
var BluebirdPromise = require('./bluebird/bluebird.js');

// Import runtime support for transpiled async code (for IE):
require('./babel/regenerator-runtime.js');

var jquery = require('./jquery/jquery.js');
require('./jquery.cookie.js');
require('./jquery/jquery.autocomplete.js');
require('./jquery/jquery.datetimepicker.js');

require('./flatpickr/flatpickr.min.js');
require('./spectrum.js');
var moment = require('./moment.js');
require('./foundation/foundation.js');
require('./jquery/jquery.joyride.js');

require('./knockout/knockout.js');
require('./knockout/knockout-es5.js');
require('./knockout/knockoutExt.js');

var Dygraph = require('./dygraph/dygraph.min.js');
require('./dygraph/dygraph-sync.js');
require('./dygraph/dygraph-extra.js');

require('./dataTables.min.js');
const { MarkerClusterer } = require('markerclusterer');

var ReconnectingWebSocket = require('./reconnecting-websocket.min.js');
var DateRangePicker = require('./daterangepicker/daterangepicker.js');


// Export globals
window.jQuery = jquery;
window.$ = jquery;
window.moment = moment;
window.Dygraph = Dygraph;
window.ReconnectingWebSocket = ReconnectingWebSocket;
window.DateRangePicker = DateRangePicker;
window.BluebirdPromise = BluebirdPromise;
window.MarkerClusterer = MarkerClusterer;


require('./leaderline/animevent.js');
require('./leaderline/defs.js');
require('./leaderline/anim.js');
require('./leaderline/leaderline.js');

