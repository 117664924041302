/*** IMPORTS FROM imports-loader ***/
'use strict';
import $ from "jquery";
import knockout from "knockout";
import knockoutES5 from "knockoutES5";

/*!
 * Knockout Custon Extensions
 */
"use strict";

ko.apply = function(model, element, excludeInherited) {
    ko.trackNested(model, excludeInherited);
    ko.applyBindings(model, element || document.querySelector('body'));
}


ko.trackNested = function(model, excludeInherited) {
    var track = function(obj) {
        if (!obj) return;
        for (var key in obj) {
            if (excludeInherited && !obj.hasOwnProperty(key))
                continue;
            var value = obj[key];
            if (typeof value === 'object' && !Array.isArray(value))
                track(value);
        }
        if (typeof obj === 'object')
            ko.track(obj);
    }

    track(model);
}


ko.applyES6 = function(model, element, excludeNestedObjs) {
    ko.trackES6(model, excludeNestedObjs);
    ko.applyBindings(model, element);
}


ko.trackES6 = function(model, excludeNestedObjs) {
    var track = function(obj) {
        if (!obj) {
            return;
        }
        if (!excludeNestedObjs) {
            for (var key in obj) {
                var value = obj[key];
                if (key && value && typeof value === 'object' && !Array.isArray(value) && key.indexOf('_') !== 0) {
                    track(value);
                }
            }
        }
        if (typeof obj === 'object') {
            var publicFields = Object.keys(obj).filter(function (key) {
                return key && key.indexOf('_') !== 0;
            });
            if (publicFields.length) {
                ko.track(obj, { fields: publicFields });
            }
        }
    }
    track(model);
    return model;
}


ko.bindingHandlers.clk = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        // Make sure the click function is correctly bound to the current context:
        var func = valueAccessor();
        var thisContext = bindingContext.$data;
        element.addEventListener('click', function() {
            func.call(thisContext);
        });
    }
};


ko.bindingHandlers.onEnter = {
    init: function (element, valueAccessor, allBindings, viewModel) {
        var callback = valueAccessor();
        $(element).keypress(function (event) {
            var keyCode = (event.which ? event.which : event.keyCode);
            if (keyCode === 13) {
                callback.call(viewModel);
                return false;
            }
            return true;
        });
    }
};


ko.bindingHandlers.optionsMap = {
    getAttrs: function(option) {
        if (!option) {
            return [];
        }
        var attrs = {};
        for (var i = 0; i < option.attributes.length; i++) {
            var attr = option.attributes[i];
            if (attr.nodeValue === undefined) {
                attrs[attr.name] = true;
            } else {
                try {
                    attrs[attr.name] = JSON.parse(attr.nodeValue);
                } catch (err) {
                    attrs[attr.name] = attr.nodeValue;
                }
            }
        }
        attrs.text = option.innerText;
        return attrs;
    },
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        // Make sure the click function is correctly bound to the current context:
        var params = valueAccessor() || {};
        var thisContext = bindingContext.$data;
        var options = [];

        for (var i = 0; i < element.options.length; i++) {
            var option = element.options[i];
            var attrs = ko.bindingHandlers.optionsMap.getAttrs(option);
            options.push(attrs);
            if (params.filter) {
                var shouldInclude = params.filter.call(thisContext, attrs);
                if (!shouldInclude) {
                    option.setAttribute('hidden', '');
                }
            }
        }

        var viewModelProp = element.attributes['selected'] && element.attributes['selected'].nodeValue;
        if (!viewModelProp) {
            return;
        }

        var observable = ko.getObservable(viewModel, viewModelProp);

        var initialOption = options[element.selectedIndex];
        observable(initialOption);
        if (params.change) {
            params.change.call(thisContext, initialOption);
        }

        element.addEventListener('change', function() {
            var newOption = options[element.selectedIndex];
            observable(newOption);
            if (params.change) {
                params.change.call(thisContext, newOption);
            }
        });
    }
};


// Custom code on top of jquery.autocomplete to allow selection when hitting tab.
$.fn.customautocomplete = function(options) {
    if (!options) {
        return;
    }
    if (typeof options === 'string') {
        $(this).autocomplete(options);
        return;
    }

    options.showHint = options.showHint || false;
    options.updateOnKeypress = typeof options.updateOnKeypress !== 'undefined' ? options.updateOnKeypress : true;
    options.minLength = typeof options.minLength !== 'undefined' ? options.minLength : 2;
    options.autoselect = typeof options.autoselect !== 'undefined' ? options.autoselect : true;
    options.enableTab = options.enableTab || false;

    var tabKey = 9;
    var currentlyHighlightedItem = null;

    var isInitialized = Boolean($(this).data('autocomplete_options'));
    if (isInitialized) {
        $(this).autocomplete('options', options);
        return;
    }

    $(this).autocomplete(options)
        .on('selected.xdsoft', function (_, item) {
            if (item) {
                options.onAutoCompSelected(item);
            }
        })
        .on('autocompleted.xdsoft', function (_, item) {
            currentlyHighlightedItem = item;
        })
        .on('keydown.xdsoft', function(event) {
            if (options.enableTab && event.keyCode === tabKey && currentlyHighlightedItem) {
                $(this).trigger('selected.xdsoft', [currentlyHighlightedItem]);
            }
            return true;
        })
        .on('close.xdsoft', function() {
            currentlyHighlightedItem = null;
        });
};


ko.bindingHandlers.autoc = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        // Make sure any function params are correctly bound to the current context.
        var params = valueAccessor();
        for (var key in params) {
            var param = params[key];
            if (typeof param === 'function' && bindingContext.$data)
                params[key] = param.bind(bindingContext.$data);
        }

        params.enableTab = true;
        $(element).customautocomplete(params);
    }
};


ko.bindingHandlers.calendarDate = {
    init: function(element, valueAccessor) {
        // Make sure any function params are correctly bound to the current context.
        var params = valueAccessor();
        if (!params || !params.date) {
            return;
        }

        var date = new Date(params.date);
        var options = {};
        if (params.format) {
            options.sameElse = options.format;
        }
        var calendarDate = moment(date).calendar(null, options);
        element.innerText = calendarDate;
    }
};


ko.bindingHandlers.bytes = {
    init: function(element, valueAccessor) {
        // Make sure any function params are correctly bound to the current context.
        var bytes = parseInt(valueAccessor());
        if (isNaN(bytes)) {
            return;
        }

        var thresh = 1000;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);

        var bytesStr = bytes.toFixed(1) + ' ' + units[u];
        element.innerText = bytesStr;
    }
};


ko.bindingHandlers.numberWithCommas = {
    init: function(element, valueAccessor) {
        var numb = parseInt(valueAccessor());
        if (isNaN(numb)) {
            element.innerText =  '0';
        } else {
            element.innerText = numb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
};


ko.bindingHandlers.moneyFromUSCents = {
    init: function(element, valueAccessor) {
        var cents = parseInt(valueAccessor());
        if (isNaN(cents)) {
            element.innerText =  '$ 0.00';
        } else {
            element.innerText = '$ ' + (cents / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
    }
};


ko.bindingHandlers.dtpicker = {
    getDateParam: function (viewModel, param, onValueChange) {
        if (typeof param === 'string' && param.charAt(0) != '#') {
            var observable = ko.getObservable(viewModel, param);
            observable.subscribe(function (newValue) {
                if (newValue && !(newValue instanceof Date)) {
                    newValue = new Date(newValue * 1000);
                }
                onValueChange(newValue);
            });
            var value = observable();
        } else {
            var value = param;
        }
        if (value && !(value instanceof Date) && !isNaN(parseInt(value))) {
            value = new Date(value * 1000);
        }

        return value;
    },
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var params = valueAccessor();
        var value = ko.bindingHandlers.dtpicker.getDateParam(viewModel, params.value, function (newValue) {
            $(element).datetimepicker('setOptions', {value: newValue});
        });
        var min = ko.bindingHandlers.dtpicker.getDateParam(viewModel, params.min, function (newMin) {
            $(element).datetimepicker('setOptions', {minDate: newMin || false});
        });
        var max = ko.bindingHandlers.dtpicker.getDateParam(viewModel, params.max, function (newMax) {
            $(element).datetimepicker('setOptions', {maxDate: newMax || false});
        });
        var timepicker = ko.bindingHandlers.dtpicker.getDateParam(viewModel, params.timepicker, function (timepicker) {
            $(element).datetimepicker('setOptions', {timepicker: (timepicker !== "undefined") ? timepicker : true});
        });
        var parentId = ko.bindingHandlers.dtpicker.getDateParam(viewModel, params.parent_id, function (newParentID) {
            $(element).datetimepicker('setOptions', {parentID: newParentID || 'body'});
        });
        var insideParent = ko.bindingHandlers.dtpicker.getDateParam(viewModel, params.inside_parent, function (newParentID) {
            $(element).datetimepicker('setOptions', {insideParent: inside_parent || false});
        });
        var zeropad = function (x) {return x < 10 ? '0' + x : '' + x;};
        var formatDate = function(dt) {
            return dt.getFullYear() + "/" + zeropad(dt.getMonth() + 1) + "/" + zeropad(dt.getDate()) +
                " " + zeropad(dt.getHours()) + ":" + zeropad(dt.getMinutes());
        }
        $(element).datetimepicker({
            step: 15,
            allowBlank: false,
            value: formatDate(value),
            minDate: min || false,
            maxDate: max || false,
            parentID: parentId || 'body',
            insideParent: insideParent,
            timepicker: (timepicker !== "undefined") ? timepicker : true,
            format: params.format || 'Y/m/d H:i',
            yearEnd: (new Date()).getFullYear(),
            onChangeYear: function (current_time, inputElement) {
                var d = new Date(current_time);
                inputElement.val(formatDate(d));
            },
            closeOnDateSelect: true
        });

        if (typeof params.value === 'string' && params.value.charAt(0) != '#') {
            var valueObservable = ko.getObservable(viewModel, params.value);
            if (valueObservable) {
                var isUsingDate = valueObservable() instanceof Date;
                $(element).on('change', function(e) {
                    var d = new Date(e.target.value);
                    if (!isUsingDate) {
                        d = parseInt(d.getTime() / 1000);
                    }
                    valueObservable(d);
                });
            }
        }
    }
};


ko.bindingHandlers.daypicker = {
    getDateParam: function (viewModel, param, onValueChange) {
        var observable = ko.getObservable(viewModel, param);
        if (observable) {
            observable.subscribe(function (newValue) {
                if (newValue) {
                    newValue = new Date(newValue.replace(/-/g, '/'));
                }
                onValueChange(newValue);
            });
            var value = observable();
        } else {
            var value = param;
        }
        if (value && !(value instanceof Date)) {
            value = new Date(value.replace(/-/g, '/'));
        }
        return value;
    },
    init: function(element, valueAccessor, allBindings, viewModel) {
        var params = valueAccessor();
        var value = ko.bindingHandlers.daypicker.getDateParam(viewModel, params.value, function (newValue) {
            $(element).datetimepicker('setOptions', {value: newValue});
        });
        var min = ko.bindingHandlers.daypicker.getDateParam(viewModel, params.min, function (newMin) {
            $(element).datetimepicker('setOptions', {minDate: newMin || false});
        });
        var max = ko.bindingHandlers.daypicker.getDateParam(viewModel, params.max, function (newMax) {
            $(element).datetimepicker('setOptions', {maxDate: newMax || false});
        });

        var formatDate = function(dt) {
            if (dt instanceof Date) {
                dt = dt.toISOString().replace(/T.*/, '');
            }
            return dt.replace(/-/g, '/');
        }

        $(element).datetimepicker({
            step: 15,
            allowBlank: false,
            timepicker: false,
            value: value,
            minDate: min || false,
            maxDate: max || false,
            format: 'Y/m/d',
            yearEnd: (new Date()).getFullYear(),
            onChangeYear: function (current_time, inputElement) {
                var d = new Date(current_time);
                inputElement.val(formatDate(d));
            },
            closeOnDateSelect: true
        });

        if (typeof params.value === 'string') {
            var valueObservable = ko.getObservable(viewModel, params.value);
            if (valueObservable) {
                $(element).on('change', function(e) {
                    var dateStr = e.target.value;
                    var isoStr = dateStr.replace(/\//g, '-');
                    valueObservable(isoStr);
                });
            }
        }
    }
};


ko.bindingHandlers.urlHash = {
    init: function(element, valueAccessor, allBindings, viewModel) {
        if (typeof valueAccessor !== 'function') {
            return;
        }

        ko.pureComputed(valueAccessor, viewModel).subscribe(function (newUrl) {
            if (newUrl === null || newUrl === undefined || newUrl === false) {
                return;
            }
            newUrl = '#' + newUrl.toLowerCase();
            if (window.location.hash === newUrl) {
                return;
            }
            // console.log('URL Hash Changed:' + newUrl);
            window.location.hash = newUrl;
        });
    }
};


ko.switchInputId = 0;
ko.components.register('switch', {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            ko.switchInputId = ko.switchInputId + 1;
            var inputName = $(componentInfo.element).attr('id');
            if (inputName) {
                inputName += '-switch';
            } else {
                inputName = 'input-switch' + ko.switchInputId;
            }

            var input = $(componentInfo.element).find('input');
            input.attr('id', inputName).attr('name', inputName);
            $(componentInfo.element).find('label').attr('for', inputName);

            var trueTxt = $(componentInfo.element).attr('true') || 'Yes';
            var trueSpan = $(componentInfo.element).find('.switch-active');
            trueSpan.html(trueTxt);

            var falseTxt = $(componentInfo.element).attr('false') || 'No';
            var falseSpan = $(componentInfo.element).find('.switch-inactive');
            falseSpan.html(falseTxt);

            var propName = $(componentInfo.element).attr('prop');
            input.attr('data-bind', 'checked: ' + propName);
            var classList = $(componentInfo.element).attr('class');
            if (classList) {
                var switchContainer = $(componentInfo.element).find('.switch');
                switchContainer.attr('class', switchContainer.attr('class') + ' ' + classList);
            }

            return params.data;
        }
    },
    template: '' +
        '<div class="switch">' +
            '<input class="switch-input" type="checkbox" />' +
            '<label class="switch-paddle">' +
                '<span class="show-for-sr"></span>' +
                '<span class="switch-active" aria-hidden="true"></span>' +
                '<span class="switch-inactive" aria-hidden="true"></span>' +
            '</label>' +
        '</div>'
});


ko.components.register('search', {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            var el = $(componentInfo.element);
            var input = el.find('input');
            var placeholder = el.attr('placeholder') || '';

            if (placeholder) {
                input.attr('placeholder', placeholder);
                el.removeAttr('placeholder');
            } else if (params.data().placeholder) {
                input.attr('placeholder', params.data().placeholder);
            }

            var id = el.attr('id') || '';
            if (id) {
                input.attr('id', id);
                el.removeAttr('id');

                var focusButton = el.find('.button.fi-magnifying-glass');
                focusButton.attr('id', id + 'FocusButton');
                var cancelButton = el.find('.button.secondary');
                cancelButton.attr('id', id + 'CancelButton');
            }

            return params.data;
        }
    },
    template: '' +
        '<div class="input-group">' +
            '<input type="text" class="input-group-field"' +
                ' data-bind="textInput: searchTxt, hasFocus: hasFocus, event: {blur: onFocusLost, keyup: checkSelection},' +
                ' autoc: $data" />' +
            '<div class="input-group-button">' +
                '<button tabindex="-1" class="button fi-magnifying-glass" data-bind="click: setFocus, visible: !selected"></button>' +
                '<button tabindex="-1" class="button secondary fi-x" data-bind="click: clear, visible: selected" style="display:none;"></button>' +
            '</div>' +
        '</div>'
});


ko.components.register('timepickerwrapper', {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            return params.data;
        }
    },
    template: '' +
    '<div class="timepickerWrapper timepicker_container" data-bind="attr:{id: $data.parent_id}, timepicker: $data">' +
        '<div class="small-12 medium-6 columns full-left">' +
            '<label>Start Time</label>' +
            '<input type="text" class="start"/>' +
        '</div>' +
        '<div class="small-12 medium-6 columns full-right">' +
            '<label>End Time</label>' +
            '<input type="text" class="end"/>' +
        '</div>' +
        '<div class="clearfix"></div>' +
    '</div>'
});

ko.bindingHandlers.timepicker = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        // Make sure any function params are correctly bound to the current context.
        var params = valueAccessor();
        for (var key in params) {
            var param = params[key];
            if (typeof param === 'function' && bindingContext.$data)
                params[key] = param.bind(bindingContext.$data);
        }

        var zeropad = function (x, pad) {
            if (!pad) {
                pad = 2;
            }
            if (x < 10 * pad) {
                x = String(x);
                var zeroes = '';
                for (var i = 0; i < pad; i++) {
                    zeroes += '0';
                }
                return zeroes.substring(0, zeroes.length - x.length) + x;
            }
            return String(x);
        }

        var $el = $(element);
        var formatTimeString = function (e) {
            return String(zeropad(Math.floor(e / 3600))) + ':' + String(zeropad(e % 3600 / 60))
        }
        $('.start', $el).datetimepicker({
            format: 'H:i',
            allowBlank: false,
            step: 15,
            value: formatTimeString(params.times[0]),
            datepicker: false,
            className: 'tpac_dates',
            id: 'tpa_compare__start_tp',
            onSelectTime: function (dp, $input) {
                var v = $input.val().split(':')
                params.times[0] = (parseInt(v[0]) * 60 + parseInt(v[1])) * 60;
                if (params.times[1] < params.times[0]) {
                    params.times[1] = params.times[0] + 60 * 15;
                    var endTimeString = formatTimeString(params.times[1])
                    $('.end', $el).datetimepicker({value: endTimeString});
                }
            },
            closeOnDateSelect: true
        });


        $('.end', $el).datetimepicker({
            format: 'H:i',
            allowBlank: false,
            step: 15,
            value: formatTimeString(params.times[1]),
            datepicker: false,
            className: 'tpac_dates',
            id: 'tpa_compare__end_tp',
            onSelectTime: function (dp, $input) {
                var v = $input.val().split(':')
                params.times[1] = (parseInt(v[0]) * 60 + parseInt(v[1])) * 60
                if (params.times[0] > params.times[1]) {
                    params.times[0] = params.times[1] - 60 * 15;
                    var startTimeString = formatTimeString(params.times[0])
                    $('.start', $el).datetimepicker({value: startTimeString});
                }
            },
            closeOnDateSelect: true
        });
    }
};
ko.components.register('daterangepickerwrapper', {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            return params.data;
        }
    },
    template: '' +
    '<div class="daterangepickerWrapper dtpicker_container" data-bind="attr:{id: $data.parent_id}, daterangepicker: $data">' +
        '<div class="small-12 medium-6 columns full-left">' +
            '<label>Start Date</label>' +
            '<input type="text" class="start" data-bind="value: start"/>' +
        '</div>' +
        '<div class="small-12 medium-6 columns full-right">' +
            '<label>End Date</label>' +
            '<input type="text" class="end" data-bind="value: end"/>' +
        '</div>' +
        '<div class="clearfix"></div>' +
    '</div>'
});

ko.bindingHandlers.daterangepicker = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        // Make sure any function params are correctly bound to the current context.
        var params = valueAccessor();
        for (var key in params) {
            var param = params[key];
            if (typeof param === 'function' && bindingContext.$data)
                params[key] = param.bind(bindingContext.$data);
        }

        params.dates.forEach(function (v, i) {
            if (v && v < 10e10) {
                v = v * 1000
            }
            params.dates[i] = new Date(v).getTime();
        })
        var DATE_FORMAT = params.DATE_FORMAT || "MM/DD/YYYY HH:mm"
        var MINDATE = moment('2011','YYYY').startOf('year').format(DATE_FORMAT)
        var MAXDATE = moment().add(1, 'day').startOf('day').format(DATE_FORMAT)

        var startDate = moment(params.dates[0])
        var endDate = moment(params.dates[1])
        if (params.allday) {
            startDate = startDate.startOf('day')
            endDate = endDate.endOf('day')
        }
        // if we modify the start or end date we must update the params
        params.dates[0] = parseInt(startDate.format('x'))
        params.dates[1] = parseInt(endDate.format('x'))

        params.start = startDate.format(DATE_FORMAT);
        params.end = endDate.format(DATE_FORMAT);

        var options = {
            "showDropdowns": true,
            "startDate": startDate,
            "endDate": endDate,
            "parentEl": "#" + params.parent_id,
            "maxDate": MAXDATE,
            "minDate": MINDATE,
            "autoApply": false,
            "alwaysShowCalendars": true,
            "applyButtonClasses": 'small-3 primary button',
            "cancelButtonClasses": 'small-3 secondary button',
            "buttonClasses": 'no-button',
            "locale": {
                'format': DATE_FORMAT
            },
            "onUpdateView": resetAdditinalsEvents,
            "onTimeChange": handleOnDateClick
        }
        if (!params.disableTime) {
            options.timePicker = true,
            options.timePicker24Hour = true;
            options.timePickerIncrement = params.resolution;
        }

        if (params.maxSpan) {
            options.maxSpan = params.maxSpan;
        }

        if (params.ranges) {
            options.ranges = params.ranges;
            
        }

        if (params.dow) {
            options.dow = params.dow;
        }

        if (params.getHolidayByDate) {
            function handleIsCustomDate(data, e) {
                const holiday = params.getHolidayByDate(data)
                const isExcluded = params.isDateExclude(data)
                
                const classes = []

                if (holiday) {
                    classes.push('is-holiday')
                    classes.push('holidayname-' + holiday.name)
                }

                if (isExcluded) {
                    classes.push('isDOW')
                }

                return classes.length ? classes : false
            }

            options.isCustomDate = handleIsCustomDate;
        }

        // initialize the display to be what we are using for the datepicker.
        params.dateLabel = startDate + " - " + endDate;
        var $el = $(element);

        $el.daterangepicker(options, function (start, end) {
            var startDate = start.format(DATE_FORMAT);
            params.start = startDate

            var endDate = moment(end).format(DATE_FORMAT)
            params.end = endDate
            params.dates = [startDate, endDate]
            params.dateLabel = startDate + " - " + endDate;
        })
        $el.on('show.daterangepicker', function(ev, picker) {
            if (params.getDOW) {
                const daysHeaders = Array.from($('.daterangepicker table thead tr:nth-child(2) th', $el));
    
                daysHeaders.forEach((elm) => {
                    if (params.getDOW().includes(elm.cellIndex)) {
                        $(elm).addClass('isDOW');
                    }
                });
    
                //do something, like clearing an input
                $('.daterangepicker table thead tr:nth-child(2) th', $el).on('click', handleDaysClick)
                
            }


            $('.is-holiday').hover(handleOnHolidayHover);
            resetAdditinalsEvents()
        });

        $('.daterangepicker', $el).ready(function() {
            const dt = $el.data('daterangepicker');

            if (!$.isEmptyObject(dt && dt.ranges)) {
                $('.daterangepicker').css('width', '750px');
            } else {
                $('.drp-calendar').css('width', '50%');
            }
        })

        function handleDaysClick(e) {
            const elm = e.target;

            const classes = Array.from(elm.classList);

            if (classes.includes('isDOW')) {
                $('.daterangepicker table thead tr:nth-child(2) th:nth-child(' + (elm.cellIndex + 1) + ')').removeClass('isDOW');
                params.onRemoveDOW(elm.cellIndex);
                if ($('.daterangepicker .applyBtn').is(':disabled')) {
                    $('.daterangepicker .applyBtn').prop('disabled', false);
                }
            } else {
                $('.daterangepicker table thead tr:nth-child(2) th:nth-child(' + (elm.cellIndex + 1) + ')').addClass('isDOW');
                params.onAddDOW(elm.cellIndex);

                if ($('.daterangepicker table thead tr:nth-child(2) th.isDOW').length === 14) {
                    $('.daterangepicker .applyBtn').prop('disabled', true);
                }
            }
        }

        function handleOnHolidayHover (ev) {
            const nameClass = Array.from(ev.currentTarget.classList).find((cl) => cl.includes('holidayname-'))

            if (nameClass) {
                const name = nameClass.split('holidayname-')[1];

                ev.currentTarget.title = name
            }
        }

        function handleOnDateClick (ev) {
            if(cntrlIsPressed) {
                ev.originalEvent.stopPropagation()
                const date = ev.target.getAttribute('data-time')
                
                params.addExcludedDay(date)

                return true
            }
        }

        function resetAdditinalsEvents () {
            if (params.getDOW) {
                const daysHeaders = Array.from($('.daterangepicker table thead tr:nth-child(2) th', $el));
    
                daysHeaders.forEach((elm) => {
                    if (params.getDOW().includes(elm.cellIndex)) {
                        $(elm).addClass('isDOW');
                    }
                });
                $('.daterangepicker table thead tr:nth-child(2) th', $el).on('click', handleDaysClick)
            }

            $('.is-holiday').hover(handleOnHolidayHover);
        }

        $(document).keydown(function(event){
            if(event.which=="17")
                cntrlIsPressed = true;
        });

        $(document).keyup(function(){
            cntrlIsPressed = false;
        });

        var cntrlIsPressed = false;
        
        $('.start', $el).on('change', function(e) {
            var dt = $el.data('daterangepicker');
            if (!dt) {
                return;
            }

            var date = new Date(e.target.value);
            if (isNaN(date.getTime())) {
                date = dt.startDate.toDate();
                e.target.value = dt.startDate.format(DATE_FORMAT);
            }
            dt.setStartDate(date);
            dt.updateView();
            params.start = dt.startDate.format(DATE_FORMAT);
        })
        $('.end', $el).on('change', function(e) {
            var dt = $el.data('daterangepicker');
            if (!dt) {
                return;
            }

            var date = new Date(e.target.value);
            if (isNaN(date.getTime())) {
                date = dt.endDate.toDate();
                e.target.value = dt.endDate.format(DATE_FORMAT);
            }
            dt.setEndDate(date);
            dt.updateView()
            params.end = dt.endDate.format(DATE_FORMAT);
        })
        // set the date range in the timepicker to include the full day.
        $('.alldayBtn', $el).on('click', function (evt) {
            params.allday = true;
            var dt = $el.data('daterangepicker');
            if (!dt) {
                return;
            }

            params.start = dt.startDate.format(DATE_FORMAT)
            if (dt.endDate === null) {
                dt.endDate = dt.startDate.endOf('day')
            }
            params.end = dt.endDate.format(DATE_FORMAT)
        })

        // set the date range in the timepicker to include the full day.
        $('.cancelBtn', $el).on('click', function (evt) {
            var dt = $el.data('daterangepicker');
            if (!dt) {
                return;
            }

            params.start = dt.oldStartDate.format(DATE_FORMAT)
            params.end = dt.oldEndDate.format(DATE_FORMAT)
        })

        $('.prev.available').on('click', function (evt) {
            $('.is-holiday').hover(handleOnHolidayHover);
        })

        $('.next.available').on('click', function (evt) {
            $('.is-holiday').hover(handleOnHolidayHover);
        })

        // turn off the allday flag
        $el.on('click', '.hourselect, .minuteselect', function  () {
            params.allday = false;
        })

        $('#' + params.parent_id + ' .drp-calendar').on('click.daterangepicker', 'th.available', function (evt) {
            resetAdditinalsEvents()
        })

        $('#' + params.parent_id + ' .drp-calendar').on('change.daterangepicker', 'select.monthselect', function (evt) {
            resetAdditinalsEvents()
        })

        $('#' + params.parent_id + ' .drp-calendar').on('change.daterangepicker', 'select.yearselect', function (evt) {
            resetAdditinalsEvents()
        })

        // update the visual start / end dates based on the calendar being selected.
        $('#' + params.parent_id + ' .drp-calendar').on('mousedown.daterangepicker', 'td.available', function (evt) {
            // additional calling so the two inputs can be set
            var dt = $el.data('daterangepicker')
            if (!dt) {
                return;
            }

            var title = $(evt.target).attr('data-title');
            var row = title.substr(1, 1);
            var col = title.substr(3, 1);
            var cal = $(evt.target).parents('.drp-calendar');
            var date = cal.hasClass('left') ? dt.leftCalendar.calendar[row][col] : dt.rightCalendar.calendar[row][col];

            // this is fired AFTER the drp has fired.  So the items are reversed.
            if (dt.endDate || date.isBefore(dt.startDate, 'day')) {
                params.end = dt.endDate.format(DATE_FORMAT);
            } else if (!dt.endDate && date.isBefore(dt.startDate)) {
                params.start = dt.startDate.format(DATE_FORMAT);
            } else {
                params.start = dt.startDate.format(DATE_FORMAT);
            }
            // handleOnDateClick(evt)
        })
    }
};

ko.bindingHandlers.datatable = {
    page: 0,
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor());
        ko.unwrap(value.data);
        var observable = valueAccessor().data;
        if (value.options.paging && observable) {
            if (!observable.subscribe) {
                observable = ko.getObservable(observable);
            }
            if (observable && observable.subscribe) {
                observable.subscribe(function (changes) {
                    var table = $(element).closest('table').DataTable();
                    ko.bindingHandlers.datatable.page = table.page();
                    table.destroy();
                }, null, 'arrayChange');
            }
        }
        var nodes = Array.prototype.slice.call(element.childNodes, 0);
        ko.utils.arrayForEach(nodes, function (node) {
            if (node && node.nodeType !== 1) {
                node.parentNode.removeChild(node);
            }
        });
        var isDatatableSelectable = valueAccessor().isSelectable;
        if (isDatatableSelectable) {
            $(element).parents('table').find('th input').parents('th').removeAttr('class');
            $(element).parents('table').find('th input').on('click', function(e) {
                var table = $(element).closest('table').DataTable();
                if (e.target.checked) {
                    table.rows().select();
                    $(element).trigger('selectAll');
                } else {
                    table.rows().deselect();
                    $(element).trigger('deselectAll');
                }
            });
            $(element).on('click', 'input:checkbox', function(e) {
                var currentSelectedCounter = valueAccessor().selectCounter;
                if (currentSelectedCounter) {
                    var observableSelectCounter = ko.getObservable(currentSelectedCounter, 'value');
                    var oldVal = observableSelectCounter();
                    observableSelectCounter(oldVal + (e.target.checked ? 1 : -1));
                } 
            }.bind(this));
        }
        $(element).closest('table').on('processing.dt', function (e, settings, processing) {
            if (!processing) {
                if (isDatatableSelectable) {
                    ko.applyBindingsToDescendants(bindingContext, element);
                    var table = $(element).closest('table').DataTable();
                    var table_trs = $(element).find('tr input:checkbox:checked')
                    table_trs.each(function(i, elem){
                        table.row($(elem).closest('tr')).select();
                    });
                }
            }
        }.bind(this));
        return ko.bindingHandlers.foreach.init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor());
        var key = 'DataTablesForEach_Initialized';
        ko.unwrap(value.data);

        var table = $(element).closest('table').DataTable();
        if (value.options.paging) {
            ko.bindingHandlers.datatable.page = table.page();
            value.options.pageLength = value.options.pageLength || 10;
            var numbItems = (value.data && value.data.length) || 0;
            if (numbItems <= value.options.pageLength) {
                value.options.paging = false;
            }
        }
        table.destroy();

        ko.bindingHandlers.foreach.update(element, valueAccessor, allBindings, viewModel, bindingContext);

        var tableElement = $(element).closest('table');

        // Make sure to exclude any defined not-searchable columns.
        var firstRow = tableElement.find('tbody tr:first-child');
        if (!value.options.aoColumns && firstRow.length) {
            var aoColumns = [];
            firstRow.find('td').each(function(i, column) {
                if ($(column).hasClass('not-searchable')) {
                    aoColumns.push({ "bSearchable": false });
                } else {
                    aoColumns.push(null);
                }
            });
            value.options.aoColumns = aoColumns;
        }

        table = tableElement.DataTable(value.options);
        if (value.options.paging) {
            if (table.page.info().pages - ko.bindingHandlers.datatable.page == 0)
                table.page(--ko.bindingHandlers.datatable.page).draw(false);
            else
                table.page(ko.bindingHandlers.datatable.page).draw(false);
        }

        var dt_wrapper = $(element).closest('.dataTables_wrapper');
        var dt_search = dt_wrapper.find('.dataTables_filter label')[0];
        if (dt_search) {
            var dt_search_label = dt_search.childNodes[0];
            dt_search_label.textContent = '';
        }

        var dt_search_input = dt_wrapper.find('.dataTables_filter input');

        if (!ko.utils.domData.get(element, key) && (value.data || value.length))
            ko.utils.domData.set(element, key, true);

        if (dt_search_input) {
            dt_search_input.attr('placeholder', value.searchPlaceholder || '');
        }
        return { controlsDescendantBindings: true };
    }
};


ko.bindingHandlers.foundation = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        $(element).foundation();
    }
};


var createEmptyGraph = function(element, params) {
    params = params || {};
    params.interactionModel = {};
    params.showLabelsOnHighlight = false;
    params.title = 'No Data Available';
    if (params.labels) {
        delete params.labels;
    }

    return new Dygraph(element, ",\n0,0", params);
}


ko.bindingHandlers.graph = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var params = valueAccessor();
        if (!params || !params.file) {
            return;
        }

        $(element).addClass('dygraph');
        var graph = createEmptyGraph(element, params);
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        // This will be called once when the binding is first applied to an element,
        // and again whenever any observables/computeds that are accessed change
        // Update the DOM element based on the supplied values here.
        var params = valueAccessor();
        if (!params || !params.file) {
            return;
        }

        if (Array.isArray(params.file)) {
            if (!params.file.length) {
                createEmptyGraph(element, params);
                return;
            }
            var entry = params.file[0];
            if (Array.isArray(entry) && !entry.length) {
                createEmptyGraph(element, params);
                return;
            }
        }

        var graph = $(element).data('graph');
        if (!graph) {
            graph = new Dygraph(element, params.file, params);
            $(element).data('graph', graph);
            return;
        }

        graph.updateOptions({ file: params.file });
    }
};